import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import Loader from "react-loader-spinner"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../components/Layout/styled"

import { LyneNewSizeSelectorLyneUpMale } from "../../components/LyneNewSizeSelector"

const SizesPage = ({ intl, data }) => {
  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [recommendedSize, setRecommendedSize] = useState("")

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      if (data.recommendation.size?.length) {
        setRecommendedSize(data.recommendation.size)

        seterrorSize("")
      } else {
        seterrorSize(
          intl.formatMessage({ id: "size_selector.size_error_message" })
        )
      }
    }
  }

  return (
    data &&
    data.allDirectusSeoTranslation.nodes.length > 0 && (
      <Fragment>
        <SectionContainer>
          <SectionInnerWrapper align="flex-start">
            <Container>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                  flex: 1,
                }}
              >
                <HeadingMen>
                  <strong>
                    Entrez vos mesures et découvrez votre taille Percko
                  </strong>
                </HeadingMen>

                <LyneNewSizeSelectorLyneUpMale
                  setLoading={setLoading}
                  onChange={handleSizeRecommendationUpdate}
                />

                {loading && (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      paddingTop: 20,
                    }}
                  >
                    <Loader
                      type="Oval"
                      color="#808080"
                      height={30}
                      width={30}
                      secondaryColor={"#696969"}
                    />
                  </div>
                )}
                {errorSize && !loading && (
                  <div className="warning-container">
                    <span
                      className="warning"
                      dangerouslySetInnerHTML={{ __html: errorSize }}
                    />
                  </div>
                )}
                {recommendedSize && !loading && (
                  <Result>
                    <span>{recommendedSize}</span>
                  </Result>
                )}
              </div>
            </Container>
          </SectionInnerWrapper>
        </SectionContainer>
      </Fragment>
    )
  )
}

const Container = styled.div`
  color: #262626;
  font-size: 14px;
  line-height: 1.6;
  font-family: "GothamLight";
  display: flex;
  width: 100%;
  flex-direction: column;

  body {
    background-color: #fff;
  }
  .container {
    padding: 20px;
  }
`

const HeadingMen = styled.h2`
  padding: 10px;
  font-size: 14px;
  color: #595959;
  font-weight: bold;
  text-align: center;
  position: relative;

  ::after {
    display: block;
    content: "";
    border-top: 1px solid #595959;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  strong {
    padding: 0 10px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    font-weight: 500;
    font-size: 14px;
  }
`

const Result = styled.div`
  margin: auto;
  font-weight: 900;
  text-align: center;
  font-size: 30pt;

  span {
    color: #262626;
    line-height: 1.6;
    font-family: "GothamLight";
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "sizes" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(SizesPage)
